import React, { Component } from "react";
import "./HomePage.scss";




export default class HomePage extends Component {

  constructor(props) {
    super(props);
  }

    render() {
        return (
          <React.Fragment>
            <div className="navbar pc">
              <img className="logo" src="images/logo.png" alt=""></img>
              <p className="navbar-option">About me</p>
              <p className="navbar-option">Web Development</p>
              <p className="navbar-option">What I am doing?</p>
            </div>
            <section className="landing-section pc">
              <img className="logo-landing" src="images/logo.png" alt=""></img>
              <div className="presentation-landing">
                <h1 className="presentation-name">Daniel González</h1>
                <h2 className="presentation-subtitle">Fullstack Developer</h2>
              </div>
            </section>
            <section className="about-section pc">
              <div className="box-content">
                <div className="about-box">
                  <div className="great-portrait">
                    <img className="daniel-photo" src="images/DANIELpng.PNG" alt=""></img>
                  </div>
                  <div className="about-info">
                    <div className="about-data">
                      <h1 className="about-title">About me</h1>
                      <p className="about-text">
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Incidunt eius eveniet minus dolorem et dignissimos reiciendis veniam vel, id asperiores inventore omnis, at, cum voluptatum excepturi vitae laboriosam repellendus expedita!
                      </p>
                    </div>
                    <div className="work-study">
                      <div className="about-work">
                        <div className="softtek">
                          <img className="mini-logo" src="images/Softtek.gif" alt=""></img>
                        </div>
                        <p>Currently working at Softtek as Frontend Developer</p>
                      </div>
                      <div className="about-work">
                        <div className="ironhack">
                          <img className="mini-logo" src="images/ironhack.png" alt=""></img>
                        </div>
                        <p>Did the Web Development Bootcamp at Ironhack </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
        </React.Fragment>
      );
    
  }
}