import React, { Component } from "react";
import { Switch, Route, Link } from "react-router-dom";

import "./App.scss";

import HomePage from "./pages/HomePage/HomePage";

export default class App extends Component {

  constructor(props) {
    super(props);

    this.state={
      language: "esp",
      selected: false,
      dark: false
    }
  }


  switchMode = () => {
    this.setState({...this.state, dark: !this.state.dark})
    if(this.state.dark){
      document.body.style.setProperty('--my-dark-background-color', '#2C2F33');
    } else{
      document.body.style.setProperty('--my-dark-background-color', 'white');
    }
  }

  render() {

    const NoMatch = ({ location }) => (
      <div className="nomatch-component">
        <h3 className="nomatch-info">
        No se encontró <code>{location.pathname}</code>
        </h3>
        <h3 className="nomatch-info">¿Estás perdido? Parece que sí</h3>
        <Link className="safe-link" to="/">
          <h3 className="nomatch-button">Sácame de aquí!</h3>
        </Link>
      </div>
    );

    return (
      <div>
          <Switch>
            <Route
              exact
              path="/"
              render={match => (
                <React.Fragment>
                  <HomePage></HomePage>
                </React.Fragment>
                
              )}
            />
            <Route component={NoMatch} />
          </Switch>
      </div>
    );
  }
}
